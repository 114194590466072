/**
 * head.js
 *
 * @version 1.0.0
 * @copyright 2018 SEDA.digital GmbH & Co. KG
 */

import lazySizes from 'lazysizes';
lazySizes.cfg.addClasses = true;
lazySizes.cfg.preloadAfterLoad = true;
lazySizes.cfg.lazyClass = 'u-lazyload';
lazySizes.cfg.preloadClass = 'u-lazypreload';
lazySizes.cfg.loadingClass = 'is-lazyloading';
lazySizes.cfg.loadedClass = 'is-lazyloaded';

// add support for background images
const mediumMediaQuery = window.matchMedia('(min-width: 640px)');
const largeMediaQuery = window.matchMedia('(min-width: 1020px)');

document.addEventListener('lazybeforeunveil', function (e) {
    let bg = e.target.dataset.bgsmall;
    if (mediumMediaQuery.matches) {
        bg = e.target.dataset.bgmedium;
    }
    if (largeMediaQuery.matches) {
        bg = e.target.dataset.bglarge;
    }
    if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});
